import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import BulbIcon from '../../../images/svg/bulb.svg'
import HandPlantIcon from '../../../images/svg/hand-plant.svg'
import ListIcon from '../../../images/svg/list.svg'
import TargetIcon from '../../../images/svg/target.svg'

const ValuesSection = () => {
  const { t } = useTranslation()
  return (
    <section className='px-4 border-b-12 lg:border-b-36 border-primary py-8 md:py-24 xl:pb-48 shadow-section relative overflow-hidden'>
      <div className='absolute bottom-0 right-0 w-full h-full'>
        <StaticImage
          src="../../../images/hero/home-hero-mobile-bottom.jpg"
          layout='fullWidth'
          transformOptions='fit'
          quality={100}
          formats={["AUTO", "WEBP", "AVIF"]}
          alt={t('valuesSection.title')}
          style={{
            height: `100%`,
          }}
        />
      </div>
      <div className='w-full max-w-screen-xl mx-auto'>
        <h2 className='text-secondary text-lg uppercase font-bold has-decorator mb-10'>{t('valuesSection.title')}</h2>
        <div className='flex flex-wrap -mx-4 md:-mx-12 relative z-10'>
          <div className='w-full lg:w-1/2 px-4 md:px-12'>
            <div className='flex flex-wrap -mx-2 md:-mx-8 -my-4 md:-my-8'>
              <div className='w-1/2 px-2 py-2 md:px-8 md:py-8' data-sal='slide-down' data-sal-delay='300' data-sal-duration='400' data-sal-easing='ease-in-out'>
                <div className='bg-secondary p-6 flex flex-col items-center justify-center'>
                  <BulbIcon width='96px' height='96px' className='flex-shrink-0 fill-current strategy-icon' />
                  <h4 className='text-primary text-center text-xs sm:text-sm xl:text-base uppercase font-bold mt-4 lg:px-8'>{t('valuesSection.boxes.01')}</h4>
                </div>
              </div>
              <div className='w-1/2 px-2 py-2 md:px-8 md:py-8' data-sal='slide-down' data-sal-delay='400' data-sal-duration='400' data-sal-easing='ease-in-out'>
                <div className='bg-secondary p-6 flex flex-col items-center justify-center'>
                  <ListIcon width='96px' height='96px' className='flex-shrink-0 fill-current strategy-icon' />
                  <h4 className='text-primary text-center text-xs sm:text-sm xl:text-base uppercase font-bold mt-4 lg:px-8'>{t('valuesSection.boxes.02')}</h4>
                </div>
              </div>
              <div className='w-1/2 px-2 py-2 md:px-8 md:py-8' data-sal='slide-up' data-sal-delay='500' data-sal-duration='400' data-sal-easing='ease-in-out'>
                <div className='bg-secondary p-6 flex flex-col items-center justify-center'>
                  <TargetIcon width='96px' height='96px' className='flex-shrink-0 fill-current strategy-icon' />
                  <h4 className='text-primary text-center text-xs sm:text-sm xl:text-base uppercase font-bold mt-4 lg:px-8'>{t('valuesSection.boxes.03')}</h4>
                </div>
              </div>
              <div className='w-1/2 px-2 py-2 md:px-8 md:py-8' data-sal='slide-up' data-sal-delay='600' data-sal-duration='400' data-sal-easing='ease-in-out'>
                <div className='bg-secondary p-6 flex flex-col items-center justify-center'>
                  <HandPlantIcon width='96px' height='96px' className='flex-shrink-0 fill-current strategy-icon' />
                  <h4 className='text-primary text-center text-xs sm:text-sm xl:text-base uppercase font-bold mt-4 lg:px-8'>{t('valuesSection.boxes.04')}</h4>
                </div>
              </div>
            </div>
          </div>
          <div className='w-full lg:w-1/2 px-4 md:px-12'>
            <div className='bg-secondary px-6 lg:px-10 py-12 mb-6 md:mb-0 mt-12 lg:mt-0'>
              <h3 className='font-medium text-primary text-lg md:text-3xl'  data-sal='slide-up' data-sal-delay='300' data-sal-duration='400' data-sal-easing='ease-in-out'>
                {t('valuesSection.header')}
              </h3>
              <div className='mt-8 lg:mt-12 mb-12 lg:mb-0 font-light md:text-lg'  data-sal='slide-up' data-sal-delay='300' data-sal-duration='400' data-sal-easing='ease-in-out'>
                <p>{t('valuesSection.lead')}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ValuesSection
