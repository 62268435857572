import * as React from 'react'
import { graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import Layout from '../components/layout'
import Seo from '../components/seo'
import ContactSection from '../components/organisms/ContactSection'
import SubpageHero from '../components/organisms/SubpageHero'
import ValuesSection from '../components/organisms/ValuesSection'
import Banner from '../components/organisms/Banner'
import Logo from '../images/svg/chanaka.svg'

const CompanyPage = ({data}) => {
  const { t } = useTranslation()
  return (
    <Layout>
      <Seo title={t('pageTitle')} />
      <SubpageHero
        heroBg={
          <StaticImage
            src="../images/company/hero-bg.jpg"
            layout='fullWidth'
            transformOptions='fit'
            quality={95}
            placeholder=''
            alt={t('pageTitle')}
            style={{
              height: `100%`,
            }}
          />
        }
        heroContent={<Logo width="501" height='43' className='max-w-full' />}
        title={t('pageTitle')}
        text={t('pageLead')}
      />
      <ValuesSection />
      <section className='px-4 border-b-12 lg:border-b-36 border-secondary py-8 md:py-24 shadow-section relative overflow-hidden'>
        <div className='w-full max-w-screen-xl mx-auto'>
          <h2 className='text-primary text-lg uppercase font-bold has-decorator mb-10'>{t('historySection.title')}</h2>
          <div className='w-full xl:w-7/12 relative'>
            <div className='pl-6 lg:pl-0 mb-12'>
              <h3
                className='font-semibold text-lg md:text-3xl'
                data-sal='slide-up' data-sal-delay='300' data-sal-duration='400' data-sal-easing='ease-in-out'
              >
                {t('historySection.header')}
              </h3>
              <div
                className='mt-8 lg:mt-12 mb-16 lg:mb-0 font-light md:text-lg'
                data-sal='slide-up' data-sal-delay='300' data-sal-duration='400' data-sal-easing='ease-in-out'
              >
                <p>
                  {t('historySection.lead')}
                </p>
              </div>
            </div>
          </div>
          <div className=''>

            {data.allStrapiHistoryEntries.edges.map(({node: entry}, index) => {
              if (index % 2 === 0) {
                return (
                  <div key={index} className='flex md:items-center -mx-4 relative pt-4 md:pt-0 md:mb-8'>
                    <div className='bg-primary h-4 w-full absolute top-0  md:right-1/2 history-entry-separator' data-sal='fade' data-sal-delay='300' data-sal-duration='400' data-sal-easing='ease-in-out'>
                    </div>
                    <div className='px-4 md:w-1/2 flex-shrink-0'>
                      <div
                        className='md:w-full flex md:justify-end relative pt-6' 
                        data-sal='slide-right' data-sal-delay='300' data-sal-duration='500' data-sal-easing='ease-in-out'
                      >
                        <div className='bg-primary rounded-full flex justify-center items-center font-semibold text-secondary text-base md:text-2xl h-20 w-20 md:h-32 md:w-32 shadow-md'>
                          {entry.year}
                        </div>
                      </div>
                    </div>
                    <div className='px-4 md:w-1/2'>
                      <div data-sal='slide-left' data-sal-delay='300' data-sal-duration='600' data-sal-easing='ease-in-out'>
                        <h4 className='text-primary font-semibold uppercase mt-6 md:-mt-1'>{entry.name}</h4>
                        <div className='font-light mt-4 xl:pr-48'>
                          <p>{entry.description}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              } else {
                return (
                  <div key={index} className='flex md:flex-row-reverse md:items-center -mx-4 relative pt-4 md:pt-0 md:mb-8'>
                    <div className='bg-secondary h-4 w-full absolute top-0 left-0 md:left-1/2 history-entry-separator' data-sal='fade' data-sal-delay='300' data-sal-duration='400' data-sal-easing='ease-in-out'></div>
                    <div className='px-2 md:w-1/2 flex-shrink-0'>
                      <div 
                        className='md:w-full flex md:justify-start relative pt-6'
                        data-sal='slide-left' data-sal-delay='300' data-sal-duration='500' data-sal-easing='ease-in-out'
                      >
                        <div className='bg-secondary rounded-full flex justify-center items-center font-semibold text-primary text-base md:text-2xl h-20 w-20 md:h-32 md:w-32 shadow-md'>
                          {entry.year}
                        </div>
                      </div>
                    </div>
                    <div className='px-2 md:w-1/2'>
                      <div className='md:text-right' data-sal='slide-right' data-sal-delay='300' data-sal-duration='600' data-sal-easing='ease-in-out'>
                        <h4 className='text-secondary font-semibold uppercase mt-6 md:-mt-1'>{entry.name}</h4>
                        <div className='font-light mt-4 xl:pl-48'>
                          <p>{entry.description}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }
            })}

          </div>
        </div>
      </section>

      <Banner
        image={
          <StaticImage
            src="../images/banner2.jpg"
            layout='fullWidth'
            transformOptions='fit'
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt={t('bannerText')}
            style={{
              height: '100%',
            }}
        />
        }
        text={t('bannerText')}
    />
      <ContactSection />
  </Layout>
  )
}

export default CompanyPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "companyPage"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allStrapiHistoryEntries(
      sort: {fields: year, order: ASC}
      filter: {locale: {eq: $language}}
    ) {
      edges {
        node {
          id
          name
          year
          description
        }
      }
    }
  }
`;